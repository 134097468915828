.section-precios{
    min-height: 619px;
    background-image: url('../../assets/AutoDLG_Home_Hero.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow{
        width: 100%;
    }
}

@media (max-width: 700px){
    .section-precios{
        padding: 77px 0 142px;
    }
}