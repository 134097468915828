.contentService{
    max-width: 1251px;
    width: 88%;
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    min-height: 277px;
   .title-servicio-div{
        display: flex;
    } 
    .servicio-div{
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
