.section-visit-us{
    min-height: 852px;
    background-image: url('../../assets/AUTO_DLG_Visitanos_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width: 100%;
    }
    .mapa{
        width: 100%;
        height: 579px;
    }
    .whatsappIcon{
        width: 21px;
        height: 22px;
    }
    a{
        text-decoration: none;
    }
}

@media(max-width: 600px){
    .section-visit-us{
        .mapa{
            height: 260px;
        }
        .whatsappIcon{
            width: 15px;
            height: 16px;
        }
    }
}