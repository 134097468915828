.graciasComponent{
    background-image: url('../../assets/AUTODLG_GRACIAS_BACKGROUND.jpg') ;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 87.3vh;
    display: flex;
    align-items: center;
    flex-direction: column;

    .contentBox{
        max-width: 1014px;
        width: 95%;
    }
    .arrow{
        max-width: 65px;
        margin-top: 132px;
        width: 30%;
    }
}