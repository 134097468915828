.iconCalendar{
    color: white;
    margin-left: 1.9%;
}

.menuitemsContentBox{
    justify-content: flex-end;
}
.MuiBox-root.css-1t6c9ts {
    justify-content: flex-end;
}
.MuiContainer-root.MuiContainer-maxWidthXl.css-19r6kue-MuiContainer-root {
    padding: 0%;
    max-width: 1200px;
    width: 90%;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.css-6jat1c {
    padding: 0 1.2% 0;
}
.enlaceinicio{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.MuiBox-root.css-1j2reqt {
    flex-grow: inherit;
}

@media (max-width: 900px){
    .MuiBox-root.css-1j2reqt {
        flex-grow: 1;
    } 
}
@media (min-width: 600px){
    .MuiContainer-root.MuiContainer-maxWidthXl.css-1ekb41w {
        max-width: 1248px;
        width: 90%;
        padding-left: 0%;
        padding-right: 0%;
    }
    .MuiContainer-root.MuiContainer-maxWidthXl.css-1ekb41w {
        width: 90%;
        padding-left: 0%;
        padding-right: 0%;
    }
    
}
