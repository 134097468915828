.footer-aut-dlg{
    background-color: #191919;
    min-height: 453px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-bottom: 35px;

    .logo{
        width: 100%;
        max-width: 317px;
    }
    a{
        text-decoration: none;
    }
    .linkDecoration{
        text-decoration: underline;
        text-decoration: none;
        color: inherit;
    }
}

@media (max-width: 600px){
    
    .footer-aut-dlg{
        padding: 50px 0 35px;
        .logo{
            max-width: 250px;
        }
    }
}