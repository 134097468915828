.section-mision-vision{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .contentImage{
        width: 100%;
        max-width: 100%;
        background-image: url('../../assets/AUTO_DLG_Mision_Vision_img.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 549px;
    }
    .columnMV{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logo{
        max-width: 135px;
        width: 90%;
        margin: auto;
    }
    .arrow{
        max-width: 65px;
        width: 100%;
    }
    .compromiso{
        width: 100%;
        max-width: 1380px;
        margin-top: 114px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media (max-width: 800px) {
    .section-mision-vision{
        .compromiso{
            margin-top: 120px;
            min-height: 644px;
        }
        .contentImage{
            min-height: 627px;
            background-position: center
        }
        .logo{
            width: 34%;
        }
    }
  }