img{
    width: 100%;
}
.MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-yw020d-MuiAccordionSummary-expandIconWrapper {
    color: white;
}

.MuiCollapse-wrapper.MuiCollapse-vertical.css-smkl36-MuiCollapse-wrapper{
    border-bottom-style: groove;
    border-color: snow;
}